import { Link } from "gatsby"
import React from "react"
import styles from "src/components/header.module.css"
import Logo from "src/components/logo"

const ListLink = props => (
  <li className={styles.nav}>
    <Link to={props.to} activeClassName={styles.active} partiallyActive={true}>{props.children}</Link>
  </li>
)

const Header = () => (
  <div className={styles.header}>
    <header style={{ marginBottom: `1.5rem` }}>
      <Link className={styles.logo} to="/">
        <Logo />
      </Link>
      {/* <ul>
        <ListLink to="/portfolio">Portfolio</ListLink>
        <ListLink to="/contact" >Contact</ListLink>
      </ul> */}
    </header>
  </div>
)

export default Header
