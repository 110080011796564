import React from "react";

const Logo = () => (
  <svg width={46} height={46} viewBox="0 0 46 46" fill="none">
    <circle cx={23} cy={23} r={23} fill="#547797" />
    <path
      d="M15.576 15.2c1.712 0 3.056.376 4.032 1.128.992.736 1.488 1.76 1.488 3.072 0 .96-.272 1.776-.816 2.448-.544.656-1.288 1.096-2.232 1.32 1.168.176 2.08.648 2.736 1.416.672.752 1.008 1.696 1.008 2.832 0 1.44-.544 2.568-1.632 3.384-1.072.8-2.56 1.2-4.464 1.2H8.4V15.2h7.176zm-.12 7.128c1.04 0 1.84-.216 2.4-.648.576-.448.864-1.08.864-1.896 0-.8-.288-1.416-.864-1.848-.56-.432-1.36-.64-2.4-.624h-4.752v5.016h4.752zm0 7.56c1.248.016 2.224-.216 2.928-.696.704-.496 1.056-1.184 1.056-2.064 0-.848-.352-1.512-1.056-1.992-.704-.48-1.68-.72-2.928-.72h-4.752v5.472h4.752zm22.198-10.656a6.78 6.78 0 00-2.232-1.56 6.279 6.279 0 00-2.616-.576 6.384 6.384 0 00-3.264.864 6.379 6.379 0 00-2.352 2.352c-.576.976-.864 2.056-.864 3.24a6.34 6.34 0 00.864 3.24 6.379 6.379 0 002.352 2.352c.992.576 2.08.864 3.264.864.88 0 1.744-.176 2.592-.528a7.682 7.682 0 002.256-1.488l1.392 1.512a9.538 9.538 0 01-2.952 1.968 8.833 8.833 0 01-3.408.696c-1.6 0-3.064-.376-4.392-1.128a8.524 8.524 0 01-3.144-3.12c-.768-1.312-1.152-2.76-1.152-4.344 0-1.568.384-3 1.152-4.296a8.472 8.472 0 013.192-3.096c1.344-.752 2.824-1.128 4.44-1.128 1.168 0 2.296.224 3.384.672a8.224 8.224 0 012.856 1.848l-1.368 1.656z"
      fill="#F5F1F5"
    />
  </svg>
);

export default Logo;